import { Alert } from "bootstrap";

export default class FicheAlertsActions {
    constructor($view) {
        this.bind();
    }

    bind() {
        // reload web page all the 30 min
        setTimeout(function () {
            window.location.reload(1);
        }, 1800000);

        var fiche = $("#fiche");
        if (fiche.data("available-message") !== "") {
            this.alertProjetNonDisponible();
        }

        window.reset = 0;

        var firstClientId = fiche.data("first-client-id");

        window.GoBack = function () {
            var url = "/Dashboard";
            window.location.href = url;
        }

        window.PrestationTermine = function (id) {

            var content = AlertsActionsRsx.Message_CloturerProjet + "<br /><br />";

            Swal.fire({
                title: AlertsActionsRsx.Label_PrestationTerminee,
                text: content,
                type: 'warning',
                html: content,
                showCancelButton: true,
                confirmButtonColor: '#2ABFBA',
                cancelButtonColor: '#E0E0E0',
                confirmButtonText: ResourceFiche.Valider,
                cancelButtonText: ResourceFiche.Annuler,
                showLoaderOnConfirm: true,
                input: 'text',
                inputPlaceholder: ResourceFiche.Placeholder_Commentaire,
                preConfirm: (comment) => {
                    return fetch("/Projet/Terminer?Wipoz_ProjetID=" + id + "&Commentaire=" + comment, {
                        method: 'POST',
                        credentials: 'include'
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(response.statusText)
                            }
                            return response;
                        })
                        .catch(error => {
                            Swal.showValidationError(
                                AlertsActionsRsx.ErrorMsg_TerminerProjet
                            );
                        });
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                location.reload();
            });
            return false;
        }

        window.PrestationCloture = function (id) {

            var content = AlertsActionsRsx.Message_CloturerProjet + "<br /><br />";

            var cloture = $("#PrestationClotureBouton");
            if (cloture.data("utilisation-mongopay-single") === "True") {
                content += cloture.data("mongopay-message")
            }

            else if (cloture.data("utilisation-mongopay") === "True") {
                content += AlertsActionsRsx.MangoPay_Enabled;
            }

            Swal.fire({
                title: AlertsActionsRsx.Title_CloturerProjet,
                type: 'warning',
                showCancelButton: true,
                html: content,
                confirmButtonColor: '#2ABFBA',
                cancelButtonColor: '#E0E0E0',
                confirmButtonText: ResourceFiche.Valider,
                cancelButtonText: ResourceFiche.Annuler,
                showLoaderOnConfirm: true,
                input: 'text',
                inputPlaceholder: ResourceFiche.Placeholder_Commentaire,
                preConfirm: (comment) => {
                    $.ajax({
                        url: "/ApporteurProjet/Cloture",
                        method: "POST",
                        data: {
                            Wipoz_ProjetID: id,
                            comments : comment
                        },
                        async: false, // Mode synchrone              
                        success: function (data) {
                            window.location = data.url;
                        },
                        error: function () {
                            Swal.showValidationError(
                                AlertsActionsRsx.ErrorMsg_CloturerProjet
                            );
                        }
                    });
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                location.reload();
            });
            return false;
        }

        window.PrestationArchive = function (id) {
            Swal.fire({
                title: AlertsActionsRsx.Title_ArchiverProjet,
                text: AlertsActionsRsx.Message_ArchiverProjet,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ABFBA',
                cancelButtonColor: '#E0E0E0',
                confirmButtonText: ResourceFiche.Valider,
                cancelButtonText: ResourceFiche.Annuler,
                showLoaderOnConfirm: true,
                input: 'text',
                inputPlaceholder: ResourceFiche.Placeholder_Commentaire,
                preConfirm: (comment) => {
                    $.ajax({
                        url: "/ApporteurProjet/Archiver",
                        method: "POST",
                        data: {
                            Wipoz_ProjetID: id,
                            comments: comment
                        },
                        async: false, // Mode synchrone              
                        success: function (data) {
                            window.location = data.url;
                        },
                        error: function () {
                            Swal.showValidationError(
                                AlertsActionsRsx.ErrorMsg_ArchiverProjet
                            );
                        }
                    });
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                location.reload();
            });
            return false;
        }

        window.AnnulationIntervenant = function (id) {

            Swal.fire({
                title: AlertActionsRsx.Title_RefuserProjet,
                text: AlertsActionsRsx.Confirm_RefuserProjet,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ABFBA',
                cancelButtonColor: '#E0E0E0',
                cancelButtonText: ResourceFiche.Non,
                confirmButtonText: ResourceFiche.Oui,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    $.ajax({
                        url: "/Projet/AnnulationPoseur",
                        method: "POST",
                        data: {
                            Wipoz_ProjetID: id
                        },
                        async: false, // Mode synchrone              
                        success: function (data) {
                            window.location = data.url;
                        },
                        error: function () {
                            Swal.showValidationError(
                                AlertsActionsRsx.ErrorMsg_RefuserProjet
                            );
                        }
                    });
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                location.reload();
            });
            return false;
        }


        window.FinishLoad = function () {
            setTimeout(function () {
                jQuery("div#commentList").scrollTop(jQuery("div#commentList")[0].scrollHeight);
            }, 1000);

        }

        window.startChange = function () {
            var endPicker = $("#DateFinPoseur").data("kendoDatePicker"),
                startDate = this.value();
            if (startDate) {
                startDate = new Date(startDate);
                startDate.setDate(startDate.getDate() + 1);
                endPicker.min(startDate);
            }
        }

        window.endChange = function () {
            var startPicker = $("#DateDebutPoseur").data("kendoDatePicker"),
                endDate = this.value();

            if (endDate) {
                endDate = new Date(endDate);
                endDate.setDate(endDate.getDate() - 1);
                startPicker.max(endDate);
            }
        }

        window.alertProjetIncomplet = function () {
            toastr.error(AlertsActionsRsx.ErrorMsg_FicheProjetIncomplet);
        }

        window.alertProjetNonDisponible = function () {
            Swal.fire({
                title: Resource.ProjetNonDisponibleTitre,
                text: Resource.ProjetNonDisponibleTexte,
                type: 'warning',
                confirmButtonColor: '#2ABFBA',
                confirmButtonText: Resource.ProjetNonDisponibleRetour,
                showLoaderOnConfirm: true
            }).then((result) => {
                window.location.href = "/Dashboard";
            });
            return false;
        }

        window.onChangeSAVSurProjet = function () {
            var model = this.dataItem(this.select());

            if (document.getElementById('Wipoz_ClientID').value === model.Wipoz_ClientID) {
                var id = model.Wipoz_ProjetID;
                var url = urlActionFicheAlertsActions.actionVoirSAV + "?Wipoz_ProjetID=" + id;
                window.location.href = url;
            }
            else {
                toastr.error(ResourceFiche.NonAutoriseCeProjet);
            }
        }

        window.EnvoiMailCoordonneesPoseurAuClientFinal = function (id) {
            Swal.fire({
                title: ResourceFiche.EnvoyerCoordonneesPoseurClientFinal_Titre,
                text: ResourceFiche.EnvoyerCoordonneesPoseurClientFinal_Text,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2ABFBA',
                cancelButtonColor: '#E0E0E0',
                confirmButtonText: ResourceFiche.Oui,
                cancelButtonText: ResourceFiche.Non,
                showLoaderOnConfirm: true,
                preConfirm: () => {

                    return fetch(urlActionFicheAlertsActions.actionEnvoiMailCoordonneesPoseurAuClientFinal + "?Wipoz_ProjetID=" + id, {
                        method: 'POST',
                        credentials: 'include'
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(response.statusText)
                            }
                            return true;
                        })
                        .catch(error => {
                            Swal.showValidationError(
                                ResourceFiche.EnvoyerCoordonneesPoseurClientFinal_Error
                            );
                        });
                },
                allowOutsideClick: () => !Swal.isLoading()
            });
            return false;
        }
    }

    alertProjetNonDisponible() {
        Swal.fire({
            title: Resource.ProjetNonDisponibleTitre,
            text: Resource.ProjetNonDisponibleTexte,
            type: 'warning',
            confirmButtonColor: '#2ABFBA',
            confirmButtonText: Resource.ProjetNonDisponibleRetour,
            showLoaderOnConfirm: true
        }).then((result) => {
            window.location.href = "/Dashboard";
        });
        return false;
    }
}